<template>
  <bcfAsideBig @close="closeMethod">
    <div v-if="MediaFeed.mandatory_read" class="banner__important">
      <span class="banner__content">
        {{ $t('account.about.newsAndUpdates.banner') }}
      </span>
    </div>
    <bcfAsideHeader :close="closeMethod">
      {{ MediaFeed.title }}
    </bcfAsideHeader>
    <bcfAsideContent v-if="!loaded">
      <div v-if="!loaded">
        <loader class="m-b-2" />
      </div>
    </bcfAsideContent>
    <bcfAsideContent v-else>
      <div
        class="blog__mainimage"
        :style="backgroundURL"
      >
        <Badge
          v-if="MediaFeed.type!==1"
          type="unread"
        >
          {{ $t('general.promo') }}
        </Badge>
      </div>

      <time
        v-if="MediaFeed.type===1"
        class="blog__date"
      >{{ formatDate(MediaFeed.date_online) }}</time>

      <time
        v-else-if="MediaFeed.date_online && MediaFeed.date_offline"
        class="blog__date"
        v-html="$t('account.about.actionsAndPromotions.valid.from', { from: formatDate(MediaFeed.date_online)}) + ' ' + $t('account.about.actionsAndPromotions.valid.to', { to: formatDate(MediaFeed.date_offline) })"
      />
      <time v-else />
      <router-link
        v-if="MediaFeed.sharepointdriveid && MediaFeed.sharepointfolderpath"
        style="text-decoration: none"
        :to="{name: 'sharepointItems', params: {driveId: MediaFeed.sharepointdriveid, itemId: MediaFeed.sharepointfolderpath}}"
      >
        <ListItem
          icon-before
          arrow
        >
          <i class="uil uil-scenery" />
          {{ $t('items.moreMedia') }}
        </ListItem>
      </router-link>
      <router-link
        v-if="MediaFeed.sharepointdriveid && !MediaFeed.sharepointfolderpath"
        style="text-decoration: none"
        :to="{name: 'sharepointRootItems', params: {driveId: MediaFeed.sharepointdriveid}}"
      >
        <ListItem
          icon-before
          arrow
        >
          <i class="uil uil-scenery" />
          {{ $t('items.moreMedia') }}
        </ListItem>
      </router-link>

      <div
        class="blog__content"
        v-html="MediaFeed.text"
      />

      <div
        v-if="items.length && loaded"
        class="products__overview"
      >
        <!-- ITEM -->
        <router-link
          v-for="(item, index) in items"
          :key="item.id"
          :to="'/items/bikes/' + item.id"
          class="product__item"
        >
          <itemcard
            :id="item.id"
            :summaryitem="item"
            :gtag-list="'Search Results'"
            :gtag-position="index + 1"
          />
        </router-link>
      </div>
    </bcfAsideContent>
    <bcfAsideFooter
      v-if="MediaFeed.social"
      style="justify-content: center;"
    >
      <social-sharing
        :url="`${shopBaseUrl}/share/v1/media/${id}?language=${language}`"
        :quote="MediaFeed.social.quote"
        :hashtags="MediaFeed.social.hashtags"
        class="button button--outline"
        inline-template
      >
        <div>
          <img :src="require('@/assets/img/icons/Facebook_logo.svg')">
          <network network="facebook">
            {{ $t('account.about.socialFeed.share') }}
          </network>
        </div>
      </social-sharing>
    </bcfasidefooter>
  </bcfAsideBig>
</template>
<script>
import { mapState } from 'vuex';
import { getMediafeedById, GetRelatedItemsByMediaID } from '@/api/api';
import bcfAsideBig from '@/components/bcfAsideBig.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import loader from '@/elements/loader.vue';
import Badge from '@/elements/Badge.vue';
import itemcard from '@/views/items/itemCard.vue';
import { formatDate } from '@/utils/dateUtils';
import ListItem from '@/elements/ListItem.vue';
import { calculateSortOnAvailability } from '@/utils/itemUtils';

export default {
  components: {
    bcfAsideBig,
    bcfAsideHeader,
    bcfAsideContent,
    bcfAsideFooter,
    Badge,
    loader,
    itemcard,
    ListItem,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    close: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      shopBaseUrl: process.env.VUE_APP_SHOP_BASE_URL,
      MediaFeed: [],
      loaded: false,
      items: [],
      language: '',
    };
  },
  computed: {
    ...mapState({
      selectedCustomer: (state) => state.customer.selectedCustomer,
    }),
    backgroundURL() {
      return `background-image:url("${this.MediaFeed.images.image.highres}");`;
    },
  },
  async mounted() {
    this.language = this.$store.state.language.currentApi;
    this.loadRelatedItemInformation();
    const result = await getMediafeedById(
      this.id,
      this.$store.state.language.currentApi,
    );
    const [MediaFeed] = result.data.result;
    this.MediaFeed = MediaFeed;
    // console.log(this.MediaFeed);
    this.loaded = true;
  },
  methods: {
    formatDate,
    closeMethod() {
      if (this.close) {
        this.close();
      } else {
        this.$router.push(this.$store.state.global.previousRoute ? this.$store.state.global.previousRoute : { name: this.MediaFeed.type === 1 ? 'newsAndUpdates' : 'actionsAndPromotions' });
      }
    },
    async loadRelatedItemInformation() {
      // get information

      this.items = await GetRelatedItemsByMediaID(
        this.$store.state.language.currentApi,
        this.$store.state.customer.selectedCustomerId,
        this.id,
      );
      this.items = this.items.sort(calculateSortOnAvailability);
    },
  },
};
</script>
