<template>
  <transition name="slide">
    <div class="overlay overlay__full">
      <aside v-click-outside="close">
        <slot />
      </aside>
    </div>
  </transition>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
  name: 'BcfAsideBig',
  directives: {
    clickOutside: vClickOutside.directive,
  },

  created() {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.keyCode === 27) {
        this.close();
      }
    };
  },

  methods: {
    close() {
      this.$emit('close', true);
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60%;
  height: 100%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 400px 0px;
}

.modal-default-button {
  float: right;
}

/*
            * The following styles are auto-applied to elements with
            * transition="modal" when their visibility is toggled
            * by Vue.js.
            *
            * You can easily play with the modal transition by editing
            * these styles.
            */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
