export const floatValue = (value) => {
  const tempval = value.toString().replace(/\./g, ','); // replace a point with comma(thousand seperators)
  const val = tempval.replace(new RegExp(',', 'g'), '.'); // replace a comma with a point(decimals)
  return parseFloat(val);
};

export const formatPrice = (price, currency) => Number(price).toLocaleString(undefined, {
  style: 'currency',
  currency,
});
