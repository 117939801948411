/* eslint-disable no-nested-ternary */
<template>
  <BcfDialog
    v-dialog-config="{
      overlay,
      scroll: 'window', // Fix reflow with searchInput
    }"
    :overlay="overlay"
    :title="$t(`account.account.myCustomers.unassignItems`)"
  >
    <Message
      type="warning"
      :icon="true"
      :content="$t('account.account.myCustomers.confirmUnassign', {relation: relationName})"
    />

    <form
      style="display: contents"
      @submit.prevent="unassign"
    >
      <div
        class="items"
      >
        <Message
          v-if="!model.items || model.items.length===0"
          type="update"
          :icon="true"
          :content="$t('account.account.myCustomers.noEquipment')"
        />
        <template v-else>
          <div
            v-for="item of model.items"
            :key="item.serial || item.id"
            class="assigned-item"
          >
            <OrderedItem
              class="assigned-item__detail"
              :item="item"
              :interactive="false"
            />
          </div>
        </template>
      </div>

      <div
        v-if="hasStock"
        class="section"
      >
        <div class="form-group">
          <label for="stockStatus">
            {{ $t('account.account.myCustomers.setStockStatus') }}
          </label>

          <status-select
            id="stockStatus"
            v-model="model.stockStatus"
            :statuses="['available','reserved','hidden']"
          />
        </div>

        <div
          v-if="['available','reserved'].includes(model.stockStatus)"
          class="form-group"
        >
          <location-select
            v-if="locations.length>1"
            class="location-select"
            :value="model.stockAddress"
            @input="selectStockLocation($event)"
          />
        </div>
      </div>
    </form>
    <template v-slot:footer>
      <button
        class="button button--secondary"
        @click.stop="close()"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        class="button button--danger"
        @click.stop="unassign"
      >
        <loading-overlay :loading="isLoading('unassign')">
          <span>{{ $t('general.yes') }}</span>
        </loading-overlay>
      </button>
    </template>
  </BcfDialog>
</template>
<script>

// import {
//   email,
// } from 'vuelidate/lib/validators';
import Message from '@/components/Message.vue';
import BcfDialog from '@/components/dialog/bcfDialog.vue';
import OrderedItem from '@/components/item/orderedItem.vue';
import loadingOverlay from '@/elements/LoadingOverlay.vue';
import statusSelect from '@/views/account/account/mystock/statusSelect.vue';
import LocationSelect from '@/views/account/account/mystock/locationSelect.vue';
import { mapState } from 'vuex';
import translations from '@/translations';
import { formatDate } from '@/utils/dateUtils';
import { UnassignFromCustomerRelation } from '@/api/api';
import { itemToSplitId } from '@/views/account/account/mycustomers/utils';

// Elements
export default {
  components: {
    OrderedItem,
    // loader,
    loadingOverlay,
    statusSelect,
    LocationSelect,
    Message,
    BcfDialog,
  },
  props: {
    overlay: {
      // This provides us control over the modal when spawned via dialogs.show(...)
      type: Object,
      default: () => null,
    },
    value: {
      type: Object,
      /**
       * Expected input format is
       *       {
       *        items: [],
       *        stockStatus: 'sold',
       *        sendEmail: true,
       *        purchaseDate: new Date(),
       *       }
       */
      default: () => null,
    },
    relation: {
      // Mainly need this to know the relationId (could've just passed that in instead)
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      loading: {

      },
      search: {
        input: '',
        items: [],
        query: '', // Which searchInput was used that resulted in searchItems
        loading: false,
      },
      model: {
        items: [],
      },
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language.current,
      user: (state) => state.auth.user,
      customer: (state) => state.customer,
      locations: (state) => state.customer.selectedCustomer.addresses,
    }),
    customerId() {
      return this.customer.selectedCustomerId;
    },
    isNewRelation() {
      return Number.isNaN(Number.parseInt(this.id, 10));
    },
    rid() {
      return this.relation?.id;
    },
    lang() {
      // Need this for the datepicker!
      return translations[this.$store.state.language.current].Datepicker;
    },
    relationName() {
      const { relation } = this;
      if (!relation) return null;
      const name = [relation.firstName, relation.lastName].filter((x) => x).join(' ');
      return name
        ? [name, relation.email ? `(${relation.email})` : null].filter((x) => x).join(' ')
        : (relation.email || `#${relation.id}`);
    },
    hasStock() {
      return this.model && this.model.items.find((i) => i.stock && i.stock.status);
    },
  },
  validations() {
    return {
      model: {
        // No real validations yet
      },
    };
  },
  watch: {
    value() {
      this.valueToModel();
    },
  },
  mounted() {
    this.valueToModel();
  },
  methods: {
    close(result, source = 'cancel') {
      return this.overlay.close(result, source);
    },
    markLoading(key = 'save', value = true) {
      this.loading = {
        ...this.loading,
        [key]: value,
      };
    },
    markLoaded(key = 'save') {
      this.markLoading(key, false);
    },
    isLoading(key = 'save') {
      return !!this.loading[key];
    },
    valueToModel(apply = true) {
      const stockAddress = this.value?.items?.map((i) => i.stock?.address).filter((x) => x)[0]?.externalid;
      const defaultAddress = this.customer.addresses?.[0]?.externalid;
      const newValue = {
        stockStatus: 'available',
        stockAddress: stockAddress ?? defaultAddress,
        // sendEmail: true,
        items: [],
        ...JSON.parse(JSON.stringify(this.value || [])), // Take a copy
      };
      if (apply) {
        this.model = newValue;
      }
      return newValue;
    },
    async unassign() {
      try {
        const { model, rid } = this;
        const cid = this.customerId;

        this.markLoading('unassign');
        await UnassignFromCustomerRelation({ cid, rid }, {
          ...model,
          items: model.items.map(itemToSplitId),
        });

        this.close(this.model, 'submit');
      } finally {
        this.markLoaded('unassign');
      }
    },
    validationState(field, ol) {
      const indexOf = ol ? this.model.findIndex((m) => m.key === ol.key) : 0;
      const olState = this.$v.model.$each[indexOf];
      const state = olState && olState[field];
      // eslint-disable-next-line no-nested-ternary
      return (state && (state.$error || state.$invalid))
        ? false // Invalid
        : !state || ([null, undefined, ''].includes(state.$model))
          ? null // Empty or unrecognized
          : true; // Valid
    },
    dateFormatter: formatDate,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.assigned-item{
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  &__detail{
    flex: 1 1 auto;
  }
}

.visibleb2c{
  font-size: 2rem;
  display: flex;
}

.dialog-overlay__modal__footer{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.section{
  margin-bottom: 2em;
}
</style>
