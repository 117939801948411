<template>
  <LightBox
    ref="lightbox"
    :images="lightboxImage"
    :show-caption="true"
    :show-light-box="false"
    :show-thumbs="false"

    @drop="onFilesDropped"
    @dragover="onFileDragover"
    @dragleave="onFileDragleave"
  >
    <template v-slot:customCaption>
      <div
        class="item-imageviewer__caption"
        @click.stop.prevent
      >
        {{ item.description }}
        <div class="item-imageviewer__caption__controls">
          <a
            v-if="editable"
            href="#"
            @click.prevent.stop="$refs.files.click();"
          >
            {{ $t('account.account.myStock.replaceImage') }}
          </a>

          <input
            ref="files"
            multiple
            type="file"
            accept="image/*"
            @change="onImageSelected($event)"
            @click.stop
          >
        </div>
      </div>
    </template>
  </LightBox>
</template>
<script>
import LightBox from '@/components/LightBox/LightBox.vue';

export default {
  name: 'ItemImageViewer',
  components: {
    LightBox,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lightboxImage: [],
      imageFailed: false,
      imageLoading: false,
      dragInProgress: false,
    };
  },
  computed: {
    customerId() {
      return this.$store.state.customer && this.$store.state.customer.selectedCustomer.id;
    },
  },
  watch: {
    item(stock) {
      this.updateToItem(stock);
    },
  },
  methods: {
    updateToItem(item) {
      if (item) {
        const itemImg = item.image || (item.item && item.item.image);
        const itemExternalId = item.externalid || (item.item && item.item.externalid);

        const image = {
          thumb: itemImg || `https://images.cyclingfactory.be/${itemExternalId}_image_400x.jpg`,
          src: itemImg || `https://images.cyclingfactory.be/${itemExternalId}_image_2048x.jpg`,
          caption: item.description,
        };
        this.lightboxImage = [{
          ...image,
          key: 'product',
        }];
      } else {
        this.lightboxImage = [];
      }
    },
    onImageSelected() {
      const { files } = this.$refs.files;
      const { item } = this;
      console.log('Image(s) selected', { files, item });

      if (files && files.length) {
        this.$emit('imageselected', {
          files,
          item,
        });
      }
    },

    show() {
      this.updateToItem(this.item);
      this.$refs.lightbox.showImage(0);
    },

    // Drag & Drop
    onFilesDropped(ev) {
      const files = [];
      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (ev.dataTransfer.items[i].kind === 'file') {
            files.push(ev.dataTransfer.items[i].getAsFile());
          }
        }
      }

      // Only interested in single files here(
      if (files.length > 0) {
        ev.preventDefault();
        const { item } = this;
        this.$emit('imageselected', {
          files,
          item,
        });
      }
      this.dragInProgress = false;
    },
    onFileDragover(ev) {
      this.dragInProgress = true;
      ev.preventDefault(); // Notify browser we will handle this drag
    },
    onFileDragleave(ev) {
      this.dragInProgress = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

.item-imageviewer__caption{
  visibility: initial;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: .5em;
  width: 100%;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-align: center;
}
.item-imageviewer__caption__controls{
  a{
    color: $light-blue;
    text-decoration: none;
  }

  input[type=file]{
    position: absolute;
    visibility: hidden;
  }
}
</style>
