<template>
  <div
    class="image-select"
    :disabled="disabled"
    @click="!disabled && $refs.files.click()"
  >
    <input
      ref="files"
      multiple
      type="file"
      :accept="allowedTypes"
      @change="onFileSelected"
    >
    <slot>
      <div
        v-if="imageSrc.error"
        class="error-message"
      >
        {{ imageSrc.error }}
      </div>
      <img
        v-else
        slot
        :src="imageSrc"
        class="image-preview"
        :style="previewStyle"
        @error="imgFailed"
      >
    </slot>
    <slot name="select-file-overlay">
      <div class="select-file-overlay">
        <i
          class="uil uil-upload upload-icon"
          :style="iconStyle"
        />
      </div>
    </slot>
    <slot
      v-if="clearable && model"
      name="clear-file-overlay"
    >
      <div class="clear-file-overlay">
        <i
          class="uil uil-trash clear-icon"
          :style="clearIconStyle"
          @click.prevent.stop="!disabled && clearFile($event)"
        />
      </div>
    </slot>
    <template v-if="loading">
      <slot name="loading-overlay">
        <loader class="loading-overlay" />
      </slot>
    </template>
  </div>
</template>
<script>

import loader from '@/elements/loader.vue';

export default {
  name: 'ImageInput',
  components: {
    loader,
  },
  props: {
    default: {
      type: String,
      default: null,
    },
    allowedTypes: {
      type: String,
      default: () => ('image/png,image/jpeg,image/webp'),
    },
    maxSize: {
      type: Number,
      default: () => 10485760, // 10mb default
    },
    value: {
      type: [String, Object],
      default: () => null,
    },
    fit: {
      type: String,
      default: () => null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      model: this.value,
      error: false,
      iconStyle: {},
      clearIconStyle: {},
    };
  },
  computed: {
    imageSrc() {
      return this.model || this.default;
    },
    previewStyle() {
      if (this.fit) {
        return { objectFit: this.fit };
      }
      return {};
    },
  },
  watch: {
    value(newVal) {
      this.model = newVal;
    },
  },
  mounted() {
    if (this.$el && this.$el.getBoundingClientRect) {
      const bbox = this.$el.getBoundingClientRect();
      this.iconStyle = {
        fontSize: `${Math.min(100, Math.min(bbox.width, bbox.height) / 2.5)}px`,
      };
      this.cleariconStyle = {
        fontSize: `${Math.min(100, Math.min(bbox.width, bbox.height) / 5)}px`,
      };
    }
  },
  methods: {
    sendFiles() {
      // Placeholder, we're not actually sending anything from this
    },
    async onFileSelected() {
      const { files } = this.$refs.files;

      // TODO: supporting multiple files here, but the component does not yet have a way to specify multiple files are desired
      const loadedFiles = await Promise.all(
        Array.from(files)
          .map(async (file) => ({
            name: file.name,
            invalidMessage: this.validate(file),
            dataUri: await (() => {
              const reader = new FileReader();
              const result = new Promise((resolve, reject) => {
                reader.onload = function (e) {
                  resolve(e.target.result);
                };
                reader.onerror = function (e) {
                  reject(e.target.result);
                };
              });
              reader.readAsDataURL(file); // convert to base64 string
              return result;
            })(),
            file,
          })),
      );

      const result = loadedFiles[0];
      this.model = result ? result.dataUri : null;
      this.$emit('input', result);
      this.$emit('change', this.model);
    },
    validate(file) {
      const allowedTypes = this.allowedTypes.split(','); // process.env.VUE_APP_MIMETYPE.split(',');
      const MAX_SIZE = this.maxSize; // process.env.VUE_APP_MAX_SIZE; // 10MB should be enough
      const tooLarge = file.size > MAX_SIZE;
      if (tooLarge) {
        return 'File is too large';
      }
      if (!allowedTypes.includes(file.type) && !allowedTypes.includes('*')) {
        return 'Filetype not allowed';
      }
      return '';
    },
    clearFile(/** MouseEvent */ ev) {
      if (ev && ev.stopImmediatePropagation) ev.stopImmediatePropagation();
      if (ev && ev.stopPropagation) ev.stopPropagation();
      this.model = null;
      this.$emit('input', null);
      this.$emit('change', this.model);
    },
    imgFailed(ev) {
      this.$emit('error', ev);
    },
  },
};
</script>

<style scoped lang="scss">
input[type=file] {
  visibility: hidden;
  position: absolute;
}

.image-select {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  /** Sensible default for avatars/logos **/
  width: 80px;
  height: 80px;
}

.image-preview {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}

.image-select:not([disabled]){
  .select-file-overlay:hover {
    opacity: 1;
    cursor: pointer;
  }
}
.image-select[disabled]{
  opacity: .75;
  filter: grayscale(.25);
}

.select-file-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  opacity: 0;

  background: rgba(255, 255, 255, .25);
  color: white;
  border: 1px solid rgba(0, 0, 0, .5);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, .5), 0 0 10px rgba(0, 0, 0, 1);

  transition: opacity 200ms;

  .upload-icon {
    font-size: 4rem;
  }
}

.clear-file-overlay {
  position: absolute;
  top: 0;
  right: 0;

  opacity: .5;

  padding: .25rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, .5), 0 0 6px rgba(0, 0, 0, 1);

  .clear-icon {
    font-size: 1.5rem;
    color: white;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.loading-overlay{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,.5);
}

.error-message{
  color: red;
  font-size: 1.5rem;
  text-align: center;
  align-self: center;
  padding: .5rem;
  font-weight: bold
}
</style>
