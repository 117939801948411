/* export const setOrderstatus = (obj) => {
    return obj.details[obj.details.length - 1].status.toString();
} */
export const currentOrderStatus = (obj) => {
  let lastStatus = 'done';
  obj.details.forEach((state) => {
    if (state.date != null) {
      lastStatus = state.status;
    }
  });
  return lastStatus;
};

export const nextOrderStatus = (obj) => {
  let lastStatus = 'done';
  const next = obj.details.find(o => !o.date);
  if (next) {
    lastStatus = next.status;
  }
  return lastStatus;
};
